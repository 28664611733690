import React from 'react';
import './About.css';
import {Link} from 'react-router-dom';


function About() { 


  return (
    <div>
      <div className='aboutheader'>
            <div id='about' >
                <div id='cbdletters' style={{marginRight:'0.6rem'}} >CBD</div>
                <div id='aboutletters'>
                    <span style={{color:'blue'}}>A</span><span style={{color:'red'}}>b</span><span style={{color:'orange'}} >o</span><span style={{color:'blue'}}>u</span><span style={{color:'green'}}>t</span>
                </div>
            </div>
            <Link to='/Hotel' style={{textDecoration: 'none'}} ><div id='abthotelletters'>Hotel </div></Link>            
            <Link to='/Restaurant' style={{textDecoration: 'none'}} ><div id='abtrestaurantletters'>Restaurant</div></Link>
            <Link to='/' style={{textDecoration: 'none'}} ><div id='abtmoscodeletters'>Moscode</div></Link>
      </div>
      <br/><br/><br/>
      <div id='abttop'  >
          &nbsp; &nbsp; &nbsp;CBD Ltd. Company located in the central business district of Toronto downtown, providing hotel and restaurant services to local community and oversea visitors. Here is the business and traffic hub, we try our best to maintain high standards to serve our customers.
      </div><br/>
      <div id='abtpolicy' style={{width:'76%',margin:'0 12% 2% 12%'}}>
        <h2 id='abtpolicyh2' style={{textAlign:'center',marginBottom:'0.7rem'}} >CBD Ltd Co. Privacy Policy</h2>
        <p>This privacy policy ("policy") will help you understand how CBD Ltd. Company ("CBD", "us", "we", "our") uses and protects the data you provide to us when you visit and use this website ("website", "service").
            We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.
        </p>
        <h4 style={{textAlign:'center',marginTop:'0.5rem',marginBottom:'0.5rem'}}>What User Data We Collect</h4>
        <p>
          When you place a purchase order in the website, we may collect the following data:<br/>
          &nbsp; &nbsp; &nbsp; •	Your credit card information.<br/>
          &nbsp; &nbsp; &nbsp; •	Your contact information and email address.<br/>
          &nbsp; &nbsp; &nbsp; •	Other information such as products or service receiver’s name, phone number, address.<br/>
          &nbsp; &nbsp; &nbsp; •	Data profile regarding your purchase history on our website.<br/>
        </p>
        <h4 style={{textAlign:'center',marginTop:'0.5rem',marginBottom:'0.5rem'}}>Why We Collect Your Data</h4>
        <p>
          We are collecting your data for several reasons:<br/>
          &nbsp; &nbsp; &nbsp; •	To better provide the products and services your needs.<br/>
          &nbsp; &nbsp; &nbsp; •	To improve our products and services.<br/>
          &nbsp; &nbsp; &nbsp; •	To send you promotional emails containing the information we think you will find interesting.<br/>
          &nbsp; &nbsp; &nbsp; •	To contact you to fill out surveys and participate in other types of market research.<br/>
          &nbsp; &nbsp; &nbsp; •	To customize our website according to your online purchases and personal preferences.<br/>
        </p>
        <h4 style={{textAlign:'center',marginTop:'0.5rem',marginBottom:'0.5rem'}}>Safeguarding and Securing the Data</h4>
        <p>
          CBD is committed to securing your data and keeping it confidential. CBD has done all in its power to prevent data theft, unauthorized access, 
          and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.
        </p>
        <h4 style={{textAlign:'center',marginTop:'0.5rem',marginBottom:'0.5rem'}}>Our Cookie Policy</h4>
        <p>
          We do not use cookies to trace, record and analyse visitors’ behavior. It is complete safe to visit our website and to know our products and services. 
        </p>
        <h4 style={{textAlign:'center',marginTop:'0.5rem',marginBottom:'0.5rem'}}>Links to Other Websites</h4>
        <p>
          Our website may contain links that lead to other websites. If you click on these links CBD is not held responsible for your data and privacy protection. 
          Visiting those websites is not governed by this privacy policy agreement. Make sure to read the privacy policy documentation of the website you go to from our website.
        </p>
        <h4 style={{textAlign:'center',marginTop:'0.5rem',marginBottom:'0.5rem'}}>Restricting the Use of your Personal Data</h4>
        <p>
          CBD will not lease, sell or distribute your personal information to any third parties, unless we have your permission. We might do so if the law forces us. 
          Your personal information will be used when we need to fulfill your purchases from this website. We may email you promotional materials if you agree to this privacy policy.
        </p>
      </div>
      <br/><br/>
      <div id='abtrestauagre' style={{width:'76%',margin:'0 12% 2% 12%'}}>
        <h2 style={{textAlign:'center',marginBottom:'0.7rem'}} >CBD Restaurant Sales Agreement</h2>
        <p style={{marginBottom:'0.5rem'}} >This Sales Agreement (the “Agreement”) is entered into effect upon online purchaser placed an order in this website, by and between CBD Restaurant, with an address of  E, 5 King St. West, Toronto M5H 1B6, the “Seller” and online purchaser, the “Buyer”, also individually referred to as “Party”, and collectively “the Parties.”</p>
        <p style={{marginBottom:'0.5rem'}} ><b>Background</b>: the Seller is a restaurant of the following goods: Restaurant Foods and Dishes; and the Buyer wishes to purchase the aforementioned Foods and Dishes. THEREFORE, the Parties agree as follows:</p>
        <p style={{marginBottom:'0.5rem'}} ><b>Sale of Goods</b>: The Seller shall make goods available for sale in this website and the Buyer purchase the “Goods” as set forth in the order the purchaser placed.</p>
        <p style={{marginBottom:'0.5rem'}} ><b>Delivery and pick up</b>: The Seller shall deliver the Goods to the Receiver at the address and time slot set forth in the placed order, delivery address is limited in M5H area, $15 delivery fee applied. Purchaser can also pick up goods at CBD Restaurant within the time slot. The Goods shall be deemed delivered when the Receiver has accepted delivery at the designated address. The shipping method shall be determined by the Seller, but the Buyer is responsible for providing complete and accurate address. In case of incorrect address resulting in delivery failure, the Buyer is responsible for all costs incurred. </p>
        <p style={{marginBottom:'0.5rem'}} ><b>Purchase Price and Payments</b>: The Seller displays prices and conditions of the Goods to the Buyer in this website.  The Buyer placed an order to make the transaction and payment with Buyer’s credit card as set forth in the order. The Seller will send a confirmation email as invoice to the Buyer provided email address. </p>
        <p style={{marginBottom:'0.5rem'}} ><b>Inspection of Goods and Rejection</b>: The Buyer is entitled to inspect the Goods upon delivery or pickup. If the Goods are unacceptable for quality reason, as different from the goods shown in this website, the Buyer must reject them at the time of delivery or pick up. The seller ensures the quality of goods, here foods and dishes within the time slot plus one hour. The Buyer is responsible to pick up or accept delivery within the time slot. Any goods not picked up or no receiver accepts goods after the time slot set forth in the order are deemed picked up or delivered.</p>
        <p style={{marginBottom:'0.5rem'}} ><b>Cancel an order</b>: The Buyer can only cancel tomorrow or later days’ order. Today’s order, i.e. the food is already in processing procedure. A 10% of total payment, or $10 minimum cancel fee applies, whichever is higher. The Buyer should place an order after a careful consideration and arrangement.</p>
        <p style={{marginBottom:'0.5rem'}} ><b>Excuse for Delay or Failure to Perform</b>: The Seller will not be liable to the Buyer for any delay, non-delivery or default of this Agreement due to labor disputes, transportation shortage, delay or shortage of materials to produce the Goods, fires, accidents, Acts of God, or any other causes outside of the Seller’s control. The Seller shall notify the Buyer immediately upon realization that it will not be able to deliver the Goods as promised. Either Party may terminate this Agreement upon such notice.</p>
        <p style={{marginBottom:'0.5rem'}} ><b>Disclaimer of Warranties</b>: THE SELLER ENSURES THE QUALITY OF GOODS, HERE FOODS AND DISHES, FOR THE TIME SLOT SET FORTH IN THE ORDER, PLUS ONE HOUR. THEN AFTER, THE SELLER EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF QUALITY OR FITNESS FOR A PARTICULAR PURPOSE. </p>
        <p style={{marginBottom:'0.5rem'}} ><b>Limitation of Liability</b>: UNDER NO CIRCUMSTANCES SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY OR ANY THIRD PARTY FOR ANY DAMAGES RESULTING FROM ANY PART OF THIS AGREEMENT SUCH AS, BUT NOT LIMITED TO, LOSS OF REVENUE OR ANTICIPATED PROFIT OR LOST BUSINESS, COSTS OF DELAY OR FAILURE OF DELIVERY, WHICH ARE NOT RELATED TO OR THE DIRECT RESULT OF A PARTY’S NEGLIGENCE OR BREACH.</p>
        <p style={{marginBottom:'0.5rem'}} ><b>Remedies and Legal Fees</b>: In the event of a dispute, the Buyer’s sole remedy for any and all losses or damages resulting from defective Goods or from any other cause will be for the purchase price of the particular Goods with respect to which losses or damages are claimed, plus any shipping costs paid by the Buyer. In the event such dispute results in legal action, the successful Party will be entitled to its legal fees, including, but not limited to its attorneys’ fees.</p>
        <p style={{marginBottom:'0.5rem'}} ><b>Legal and Binding Agreement</b>: This Agreement is legal and binding between the Parties as stated above. This Agreement may be entered into and is legal and binding in Canada. The Parties each represent that they have the authority to enter into this Agreement.</p>
        <p style={{marginBottom:'0.5rem'}} ><b>Governing Law and Jurisdiction</b>: The Parties agree that this Agreement shall be governed by Ontario Canada in which both Parties enter into the transaction. </p>
        <p style={{marginBottom:'0.5rem'}} ><b>Entire Agreement</b>: The Parties acknowledge and agree that this Agreement represents the entire agreement between the Parties. The Parties agree to the terms and conditions set forth above.</p>
      </div>
      <br/><br/>
      <div id='abthotelagre' style={{width:'76%',margin:'0 12% 2% 12%'}}>
        <h2 style={{textAlign:'center',marginBottom:'0.7rem'}} >CBD Hotel Room Order Agreement</h2>
        <p style={{marginBottom:'0.5rem'}} >This Agreement for CBD Hotel Room Order is made and entered into effective as of the time when customer placed the room order. It is fully executed by and between CBD Hotel and Customer. </p>
        <p style={{marginBottom:'0.5rem'}} ><b>Accommodation</b>: Room accommodations are shown on this website. Customer Check-in and Check-out time, the maximum persons allowed in room are decided when customer placed the order. </p>
        <p style={{marginBottom:'0.5rem'}} >One room has only one free parking seat allowed in the designated customer parking lot. If customer has more vehicles, paid parking seats can be used. </p>
        <p style={{marginBottom:'0.5rem'}} ><b>Responsibility</b>: Customer is responsible for keeping hotel equipments and furniture safe and intact. If any damage on hotel equipments and furniture is due to customer’s cause, a fair value and related fees will be charged on customer. </p>
        <p style={{marginBottom:'0.5rem'}} >Customer is responsible for arrival and departure on time. CBD Hotel has no responsibility on the missed days and services due to customer late arrival or early departure. </p>
        <p style={{marginBottom:'0.5rem'}} ><b>Room Cancellation</b>: If customer cancels a room order, CBD Hotel will refund customer based on room resale possibility. So what time customer cancels the room order matters. If customer cancels a room order of 50 days away from today, a refund of 90% payment will be given to customer. If customer cancels a room order of between 25 to 50 days away from today, a refund of 80% payment will be given to customer. If customer cancels a room order of within 25 days from today, a refund of 70% payment will be given to customer. Even the arrival day is past, if customer still has remaining overnight days, he can get a refund of the 70% payment for the remaining overnight days if he cancels the room order. </p>
        <p style={{marginBottom:'0.5rem'}} ><b>Applied Laws</b>: If any dispute or conflict arises between CBD Hotel and its customer, and cannot solved by negotiation or arbitration, Ontario laws apply. </p>
      </div>
      <br/><br/><br/><br/>     

    </div>
  )
}

export default About
