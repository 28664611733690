import React, {useContext, useEffect, useState} from 'react'
import './CancelOrder.css';
import {Link} from 'react-router-dom'
import shoppingcart from '../images/shoppingcart.png';
import DishContext from '../DishContext';
import axios from 'axios';

function CancelOrder() {
  const formatDate = (date) => {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-');
  };
  let thisday = new Date();
  let tomorrow = formatDate(thisday.setDate(thisday.getDate()+1));
  let clmaxdate =formatDate(thisday.setDate(thisday.getDate()+10));
  const { cartItemQty } = useContext(DishContext);
  const [clMealDate, setClMealDate] = useState('');
  
  const [clMealOrder, setClMealOrder] = useState('');
  const [clMealEmail, setClMealEmail] = useState('');
  const [clCardType, setClCardType] = useState('');
  const [cardRegex, setCardRegex] = useState('')
  useEffect(()=> {
    if(clCardType==='mastercard'){setCardRegex(/^(?:5[1-5][0-9]{14})$/)}else if(clCardType==='visa'){setCardRegex(/^4[0-9]{12}(?:[0-9]{3})?$/)};
  },[clCardType])
  const [nameOnCard, setNameOnCard] = useState('');
  const [clCardNum, setClCardNum] = useState('');
  const [clInputError, setClInputError ] = useState('');
  
  const cancelOrder = () => {
    if(!clMealDate){
      setClInputError("Please select your meal order's date");
      setTimeout(() => {setClInputError('');}, 7000);
      return false;
    } 
    if(!clMealOrder || clMealOrder.length !== 13){
      setClInputError('Please enter the valid meal order number');
      document.getElementById('mealordernum').focus();
      setTimeout(() => {setClInputError('');}, 7000);
      return false;
    } 
    if(!(/^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+([a-z0-9]+)*(\.[a-z0-9]+([a-z0-9]+)*)*\.[a-z]{2,4}$/).test(clMealEmail)){
      setClInputError('Please enter your valid email on original order');
      document.getElementById('clmealemail').focus();
      setTimeout(() => {setClInputError('');}, 7000);
      return false;
    }
    if(!clCardType){
      setClInputError('Please select Mastercard or Visa Card on original order');
      setTimeout(() => {setClInputError('');}, 7000);
      return false;
    }    
    if(!nameOnCard || nameOnCard.length < 2){
      setClInputError('Please enter the valid name on credit card');
      document.getElementById('clnameoncard').focus();
      setTimeout(() => {setClInputError('');}, 7000);
      return false;
    }  
    if(!(cardRegex).test(clCardNum)) {
      setClInputError('Please enter valid credit card number');
      document.getElementById('clcardnum').focus();
      setTimeout(() => {setClInputError('');}, 7000);
      return false;
    }
    axios.post('https://api.cbdhotelrestaurant.com:443/clMealOrder', {clMealDate: clMealDate, clMealOrder: clMealOrder, clMealEmail: clMealEmail, clCardType: clCardType, 
               nameOnCard: nameOnCard, clCardNum: clCardNum})
              .then(res=>{
                  if (res.data==='Cancelled') {
                      setClInputError('Order cancelled! Check your email.');
                      setTimeout(()=>{document.getElementById('clorderfb').style.color='green';}, 100)
                      setTimeout(() => {setClInputError('');}, 7000); 
                      setTimeout(() => {window.location.reload(true);}, 8000);
                  } else {
                    setClInputError('Meal order not found!');
                    setTimeout(() => {setClInputError('');}, 7000);
                  }
              })
              .catch(function(error) {
                setClInputError('Error. Meal order not found!');
                setTimeout(() => {setClInputError('');}, 7000);
              })    
  }

  return (
    <div>
        <div className='resturantheader'>
          <div id='resturant' >
              <div id='cbdletters' style={{marginRight:'0.6rem'}} >CBD</div>
              <Link to='/Restaurant' style={{textDecoration: 'none'}} ><div id='clresturantletters'>Restaurant</div></Link>
          </div>
          <div id='clcheckoutletters'>
              <Link to='/Checkout' style={{textDecoration: 'none'}} ><span>Checkout</span></Link><span>                                 
              <img src={shoppingcart} width='15' alt='shopping-cart'/></span><span id='itemsnum' style={{fontSize:'0.8rem', color:'red'}}>{cartItemQty}</span>
          </div>
          <div id='clcancelorderletters'>         
              <span style={{color:'blue'}}>C</span><span style={{color:'red'}}>a</span><span style={{color:'orange'}} >n</span><span style={{color:'blue'}}>c</span><span style={{color:'green'}}>e</span><span style={{color:'fuchsia'}}>l</span>
              <span style={{color:'navy'}}>&nbsp;</span><span style={{color:'red'}}>O</span><span style={{color:'blue'}}>r</span><span style={{color:'red'}}>d</span><span style={{color:'orange'}} >e</span><span style={{color:'blue'}}>r</span>
          </div> 
          <Link to='/' style={{textDecoration: 'none'}} ><div id='clrest-moscodeletters'>Moscode</div></Link>
        </div>
        <div id='cancelmealorderintro' >
            <p>Due to the nature of restaurant food dishes, <b style={{color:'red'}}>you can not cancel today's meal order</b> as the food materials have already been purchased and under processing.</p><br/>
            <p>You can only cancel tomorrow or later day's meal order. A 10% of your total payment or minimun $10 <b style={{color:'red'}}>cancel fee applies.</b> The remaining 90% or net of minimun $10 of your total payment will be refunded into your credit card within 10 business days.</p>
        </div>
        <div id='cancelmealinfo'>
            <div style={{textAlign:'center'}} ><b>All information provided below should be same with your orignal meal order</b></div><br/>
            <div><label style={{fontWeight:'bold'}}>Date of Meal: </label><br/><input id='clmealdate' type='date' onChange={(e)=>setClMealDate(e.target.value)} min={tomorrow} max={clmaxdate} style={{width:'18rem',height:'1.8rem',fontSize:'0.9rem'}} /></div><br/>
            <div><label style={{fontWeight:'bold'}}>Order Number: </label><br/><input id='mealordernum' type='number' onChange={(e)=>setClMealOrder(e.target.value)} style={{width:'18rem',height:'1.8rem',fontSize:'0.9rem'}} /></div><br/>
            <div><label style={{fontWeight:'bold'}}>Your Email: </label><br/><input id='clmealemail' type='email' onChange={(e)=>setClMealEmail(e.target.value)} style={{width:'18rem',height:'1.8rem',fontSize:'0.9rem'}} /></div><br/>
            <div><p><label style={{fontWeight:'bold'}}>Credit Card: </label></p><br/><input onChange={e=>setClCardType(e.target.value)} type='radio' name='clcardtype' value='mastercard' /> MasterCard &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <input onChange={e=>setClCardType(e.target.value)} type='radio' name='clcardtype' value='visa' /> Visa</div><br/>
            <div><label style={{fontWeight:'bold'}}>Name on Card: </label><br/><input id='clnameoncard' type='text' onChange={(e)=>setNameOnCard(e.target.value)} style={{width:'18rem',height:'1.8rem',fontSize:'0.9rem'}} /></div><br/>
            <div><label style={{fontWeight:'bold'}}>Card Number: </label><br/><input id='clcardnum' type='number' onChange={(e)=>setClCardNum(e.target.value)} style={{width:'18rem',height:'1.8rem',fontSize:'0.9rem'}} /></div><br/><br/>

            {clInputError!=='' && <div id='clorderfb' >{clInputError}</div>}
            <br/><div><button id='clmealbtn' onClick={()=>cancelOrder()}>Cancel Order</button></div><br/><br/><br/>
            <div style={{width:'80%',margin:'0 0 1% 9%'}}><hr/></div>            
        </div>
    </div>
  )
}

export default CancelOrder
