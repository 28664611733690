import {createContext, useEffect, useState} from "react";
import axios from 'axios';

const DishContext = createContext();

export function DishProvider({children}) {
    const [dishqty, setDishqty] = useState([]);
    const handleDateChange = (e) => {
        e.preventDefault();
        let dishdate = document.getElementById('dateinput').value;
        axios.get('https://api.cbdhotelrestaurant.com:443/dishQty/' + dishdate).then(res=>{
            setDishqty(res.data);
        })
        setServiceTime([]);
        setDishCart([]);
        setStShow('');
    }
    const [dishes, setDishes] = useState([]);
    useEffect(()=> {
        setDishes([
            {id:"dish1", name:"Beef Steak", price:"19.80", image:`${process.env.PUBLIC_URL}/pics/dish1.jpg`, remainqty:`${dishqty.dish1}`, yourqty:""},
            {id:"dish2", name:"Beef Meatball", price:"19.80", image:`${process.env.PUBLIC_URL}/pics/dish2.jpg`, remainqty:`${dishqty.dish2}`, yourqty:""},
            {id:"dish3", name:"Grilled Lamb Chops & Chili Sauce", price:"19.80", image:`${process.env.PUBLIC_URL}/pics/dish3.jpg`, remainqty:`${dishqty.dish3}`, yourqty:""},
            {id:"dish4", name:"Braised Pork Steak", price:"15.80", image:`${process.env.PUBLIC_URL}/pics/dish4.jpg`, remainqty:`${dishqty.dish4}`, yourqty:""},
            {id:"dish5", name:"Fried Chicken Breast", price:"15.80", image:`${process.env.PUBLIC_URL}/pics/dish5.jpg`, remainqty:`${dishqty.dish5}`, yourqty:""},
            {id:"dish6", name:"Roast Chicken", price:"15.80", image:`${process.env.PUBLIC_URL}/pics/dish6.jpg`, remainqty:`${dishqty.dish6}`, yourqty:""},
            {id:"dish7", name:"Crispy Chicken Wing Root", price:"15.80", image:`${process.env.PUBLIC_URL}/pics/dish7.jpg`, remainqty:`${dishqty.dish7}`, yourqty:""},
            {id:"dish8", name:"Fried Large Shrimps 8", price:"15.80", image:`${process.env.PUBLIC_URL}/pics/dish8.jpg`, remainqty:`${dishqty.dish8}`, yourqty:""},
            {id:"dish9", name:"Boiled Medium Shrimp 22", price:"19.80", image:`${process.env.PUBLIC_URL}/pics/dish9.jpg`, remainqty:`${dishqty.dish9}`, yourqty:""},
            {id:"dish10", name:"Fried Cod with Black Pepper 2", price:"15.50", image:`${process.env.PUBLIC_URL}/pics/dish10.jpg`, remainqty:`${dishqty.dish10}`, yourqty:""},
            {id:"dish11", name:"Fish Fillet in Sour Soup", price:"19.80", image:`${process.env.PUBLIC_URL}/pics/dish11.jpg`, remainqty:`${dishqty.dish11}`, yourqty:""},
            {id:"dish12", name:"Wakame Pork Meatball Soup", price:"15.50", image:`${process.env.PUBLIC_URL}/pics/dish12.jpg`, remainqty:`${dishqty.dish12}`, yourqty:""},
            {id:"dish13", name:"Tomato and Egg Soup", price:"5.50", image:`${process.env.PUBLIC_URL}/pics/dish13.jpg`, remainqty:`${dishqty.dish13}`, yourqty:""},
            {id:"dish14", name:"Egg Tomato mixed with Lettuce Leaves", price:"7.80", image:`${process.env.PUBLIC_URL}/pics/dish14.jpg`, remainqty:`${dishqty.dish14}`, yourqty:""},
            {id:"dish15", name:"European Bread 3", price:"4.50", image:`${process.env.PUBLIC_URL}/pics/dish15.jpg`, remainqty:`${dishqty.dish15}`, yourqty:""},
            {id:"dish16", name:"Yellow Peach Jam Cake Roll", price:"5.50", image:`${process.env.PUBLIC_URL}/pics/dish16.jpg`, remainqty:`${dishqty.dish16}`, yourqty:""},
        ])
    },[dishqty])

    const [serviceTime, setServiceTime] = useState([]);
    const [st, setSt] = useState();
    
    const [stShow, setStShow] = useState();
    useEffect(()=> {
        if(st==='st1'){setStShow('10:30 -- 11:00')}else if(st==='st2'){setStShow('11:00 -- 11:30')}else if(st==='st3'){setStShow('11:30 -- 12:00')}else
        if(st==='st4'){setStShow('12:00 -- 12:30')}else if(st==='st5'){setStShow('12:30 -- 13:00')}else if(st==='st6'){setStShow('13:00 -- 13:30')}else
        if(st==='st7'){setStShow('16:30 -- 17:00')}else if(st==='st8'){setStShow('17:00 -- 17:30')}else if(st==='st9'){setStShow('17:30 -- 18:00')}else
        if(st==='st10'){setStShow('18:00 -- 18:30')}else if(st==='st11'){setStShow('18:30 -- 19:00')}else if(st==='st12'){setStShow('19:00 -- 19:30')}
    },[st])

    const [dishCart, setDishCart] = useState([]);
    const [cartItemQty, setCartItemQty] = useState();
    useEffect(()=> {
        setCartItemQty(dishCart.reduce((accum, current) => accum + Number(current[4]), 0));
    },[dishCart])

    

    return (
        <DishContext.Provider value={{ dishqty, cartItemQty, handleDateChange, dishes, serviceTime, setServiceTime, dishCart, setDishCart,
             setSt, st, stShow }}>
            {children}
        </DishContext.Provider>
    )
}

export default DishContext;
