import React, { useEffect, useState } from 'react'
import './CancelRoom.css';
import { Link } from 'react-router-dom'
import axios from 'axios';

function CancelRoom() {
  const formatDate = (date) => {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  };
  let thisday = new Date();
  let arrivalmin = formatDate(thisday.setDate(thisday.getDate() - 30));
  let arrivalmax = formatDate(thisday.setDate(thisday.getDate() + 150));
  let thatday = new Date();
  let departmin = formatDate(thatday.setDate(thatday.getDate() + 2));
  let departmax = formatDate(thatday.setDate(thatday.getDate() + 130));

  const [clArrivalDate, setClArrivalDate] = useState('');
  const [clDepartDate, setClDepartDate] = useState('');
  const [clLastDay, setClLastDay] = useState('');
  useEffect(() => {let [y, m, d] = clDepartDate.split('-'); const cllastdate = new Date(y, m-1, d);
    setClLastDay(formatDate(cllastdate.setDate(cllastdate.getDate()-1)))}, [clDepartDate]);
  const [departmin2, setDepartmin2] = useState();
  useEffect(()=>{ setDepartmin2(formatDate((new Date(clArrivalDate)).setDate((new Date(clArrivalDate)).getDate()+2))) }, [clArrivalDate]);
  
  const [clRoomOrder, setClRoomOrder] = useState('');
  const [clRoomEmail, setClRoomEmail] = useState('');
  const [clRoomCardType, setClRoomCardType] = useState('');
  const [cardRegex, setCardRegex] = useState('')
  useEffect(() => {
    if (clRoomCardType === 'mastercard') { setCardRegex(/^(?:5[1-5][0-9]{14})$/) } else if (clRoomCardType === 'visa') { setCardRegex(/^4[0-9]{12}(?:[0-9]{3})?$/) };
  }, [clRoomCardType])
  const [clRoomNameOnCard, setClRoomNameOnCard] = useState('');
  const [clRoomCardNum, setClRoomCardNum] = useState('');
  const [clRoomInputError, setClRoomInputError] = useState('');

  const cancelRoomOrder = () => {
    if (!clArrivalDate) {
      setClRoomInputError("Please select your room order's arrival date");
      setTimeout(() => { setClRoomInputError(''); }, 7000);
      return false;
    }
    if (!clDepartDate) {
      setClRoomInputError("Please select your room order's departure date");
      setTimeout(() => { setClRoomInputError(''); }, 7000);
      return false;
    }
    if (clArrivalDate >= clDepartDate) {
      setClRoomInputError("Departure date must later than arrival date");
      setTimeout(() => { setClRoomInputError(''); }, 7000);
      return false;
    }
    if (!clRoomOrder || clRoomOrder.length !== 14) {
      setClRoomInputError('Please enter the valid room order number');
      document.getElementById('roomordernum').focus();
      setTimeout(() => { setClRoomInputError(''); }, 7000);
      return false;
    }
    if (!(/^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+([a-z0-9]+)*(\.[a-z0-9]+([a-z0-9]+)*)*\.[a-z]{2,4}$/).test(clRoomEmail)) {
      setClRoomInputError('Please enter your valid email on original order');
      document.getElementById('clroomemail').focus();
      setTimeout(() => { setClRoomInputError(''); }, 7000);
      return false;
    }
    if (!clRoomCardType) {
      setClRoomInputError('Please select Mastercard or Visa Card on original order');
      setTimeout(() => { setClRoomInputError(''); }, 7000);
      return false;
    }
    if (!clRoomNameOnCard || clRoomNameOnCard.length < 2) {
      setClRoomInputError('Please enter the valid name on credit card');
      document.getElementById('clroomnameoncard').focus();
      setTimeout(() => { setClRoomInputError(''); }, 7000);
      return false;
    }
    if (!(cardRegex).test(clRoomCardNum)) {
      setClRoomInputError('Please enter valid credit card number');
      document.getElementById('clroomcardnum').focus();
      setTimeout(() => { setClRoomInputError(''); }, 7000);
      return false;
    }
    axios.post('https://api.cbdhotelrestaurant.com:443/clRoomOrder', {
      clArrivalDate: clArrivalDate, clDepartDate: clDepartDate, clLastDay: clLastDay, clRoomOrder: clRoomOrder, clRoomEmail: clRoomEmail, clRoomCardType: clRoomCardType,
      clRoomNameOnCard: clRoomNameOnCard, clRoomCardNum: clRoomCardNum
    })
      .then(res => {
        if (res.data === 'Cancelled') {
          setClRoomInputError('Order cancelled! Check your email.');
          setTimeout(() => { document.getElementById('clroomorderfb').style.color = 'green'; }, 100)
          setTimeout(() => { setClRoomInputError(''); }, 7000);
          setTimeout(() => { window.location.reload(false); }, 8000);
        } else if (res.data === 'Outdated') {
          setClRoomInputError('Today is your last night, no refund.');
          setTimeout(() => { document.getElementById('clroomorderfb').style.color = 'red'; }, 100)
          setTimeout(() => { setClRoomInputError(''); }, 7000);
          setTimeout(() => { window.location.reload(true); }, 8000);
        } else {
          setClRoomInputError('Room order not found!');
          setTimeout(() => { document.getElementById('clroomorderfb').style.color = 'red'; }, 100)
          setTimeout(() => { setClRoomInputError(''); }, 7000);
        }
      })
      .catch(function (error) {
        setClRoomInputError('Error. Cannot find your room order.');
        setTimeout(() => { document.getElementById('clroomorderfb').style.color = 'red'; }, 100)
        setTimeout(() => { setClRoomInputError(''); }, 7000);
      })
  }


  return (
    
    <div>
      <div className='hotelheader'>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div id='cbdletters' style={{ marginRight: '0.6rem' }} >CBD</div>
          <div id='hotelletters'>
            <span style={{color:'blue'}}>C</span><span style={{color:'red'}}>a</span><span style={{color:'orange'}} >n</span><span style={{color:'blue'}}>c</span><span style={{color:'pink'}}>e</span><span style={{color:'green'}}>l</span> <span style={{color:'fuchsia'}}>R</span><span style={{color:'blue'}}>o</span><span style={{color:'red'}}>o</span><span style={{color:'green'}}>m</span>
          </div>
        </div>
        <Link to='/Hotel' style={{textDecoration:'none'}} ><div id='roombookingletters'>Room Booking</div></Link>
        <Link to='/' style={{textDecoration:'none'}} ><div id='clroom-moscodeletters'>Moscode</div></Link>
      </div>
      <div id='cancelroomorderintro' >
        <p>Even your arrival date is before today, if your departure date is after tomorrow, you still can get 70% refund for the remaing days after today. <b style={{color:'red'}}>You can not get refund for today and the days bofore today.</b> You are responsible for your arrival on time.</p><br />
        <p>You can get 90% refund if your arrival date is 50 days later from today, 80% refund if your arrival date is between 25 and 50 days from today, 70% refund if your arrival date is within 25 days from today. <b style={{color:'red'}}>The deducted part is your cancellation fee, which is not less than $50.</b> The refund will be transferred to your credit card within 10 business days.</p>
      </div>
      <div id='cancelroominfo'>
        <div style={{textAlign:'center'}} ><b>All information provided below should be same with your orignal room order</b></div><br />
        <div><label style={{fontWeight:'bold'}}>Arrival Date: </label><br /><input id='clarrivaldate' type='date' onChange={(e)=>setClArrivalDate(e.target.value)} min={arrivalmin} max={arrivalmax} style={{width:'18rem',height:'1.8rem',fontSize:'0.9rem'}} /></div><br />
        <div><label style={{fontWeight:'bold'}}>Departure Date: </label><br /><input id='cldepartdate' type='date' onChange={(e)=>setClDepartDate(e.target.value)} min={clArrivalDate==='' ? departmin : departmin2} max={departmax} style={{width:'18rem',height:'1.8rem', fontSize:'0.9rem'}} /></div><br />
        <div><label style={{fontWeight:'bold'}}>Order Number: </label><br /><input id='roomordernum' type='text' onChange={(e)=>setClRoomOrder(e.target.value)} placeholder='Enter room order number, no space' style={{width:'18rem',height:'1.8rem',fontSize:'0.9rem'}}/></div><br />
        <div><label style={{fontWeight:'bold'}}>Your Email: </label><br /><input id='clroomemail' type='email' onChange={(e)=>setClRoomEmail(e.target.value)} style={{width:'18rem',height:'1.8rem',fontSize:'0.9rem'}}/></div><br />
        <div><p><label style={{fontWeight:'bold'}}>Credit Card: </label></p><br /><input onChange={e=>setClRoomCardType(e.target.value)} type='radio' name='clcardtype' value='mastercard' /> MasterCard &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <input onChange={e=>setClRoomCardType(e.target.value)} type='radio' name='clcardtype' value='visa' /> Visa</div><br />
        <div><label style={{fontWeight:'bold'}}>Name on Card: </label><br /><input id='clroomnameoncard' type='text' onChange={(e)=>setClRoomNameOnCard(e.target.value)} style={{ width: '18rem', height: '1.8rem', fontSize: '0.9rem' }} /></div><br />
        <div><label style={{fontWeight:'bold'}}>Card Number: </label><br /><input id='clroomcardnum' type='number' onChange={(e)=>setClRoomCardNum(e.target.value)} style={{width: '18rem', height: '1.8rem', fontSize: '0.9rem' }} /></div><br /><br />

        {clRoomInputError !== '' && <div id='clroomorderfb' >{clRoomInputError}</div>}
        <br /><div><button id='clroombtn' onClick={()=>cancelRoomOrder()}>Cancel Room Order</button></div><br /><br /><br />
        <div style={{width:'80%',margin:'0 0 1% 9%'}}><hr /></div>
      </div>
    </div>

  )
}

export default CancelRoom
