import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios';
import './Checkout.css';
import {Link} from 'react-router-dom'
import shoppingcart from '../images/shoppingcart.png';
import restaurant from '../images/restaurant.jpg';
import DishContext from '../DishContext';


function Checkout() {
  const { cartItemQty, serviceTime, st, stShow, dishCart, setDishCart, } = useContext(DishContext);
  const removeFromCart = (item) => {
    setDishCart(dishCart.filter((x)=>x[0]!== item[0]));
  }
  let orderDate = serviceTime.servicedate
  const [subtotal, setSubtotal] = useState();
  useEffect(()=> {
    setSubtotal(Number(dishCart.reduce((accum, current) => accum + (parseFloat(current[2])*parseFloat(current[4])), 0)).toFixed(2));
  },[dishCart])
  const [pickupDeliver, setPickupDeliver] = useState("Pickup");
  const [shoppingFee, setShoppingFee] = useState();
  useEffect(()=> {
    if(pickupDeliver==='Pickup'){setShoppingFee(Number(0).toFixed(2))}else if(pickupDeliver==='Deliver'){setShoppingFee(Number(15).toFixed(2))};
  },[pickupDeliver])
  const [tax, setTax] = useState();
  useEffect(()=> {
    setTax(((Number(subtotal) + Number(shoppingFee))*0.13).toFixed(2))
  },[subtotal,shoppingFee]);
  const [totalPayment, setTotalPayment] = useState();
  useEffect(()=> {
    setTotalPayment(Number(Number(subtotal)+Number(shoppingFee)+Number(tax)).toFixed(2));
  },[subtotal,shoppingFee,tax]);

  const [deliverAdd, setDeliverAdd] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [receiverPhone, setReceiverPhone] = useState('');

  const [cardType, setCardType] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardNum, setCardNum] = useState();
  const [cardRegex, setCardRegex] = useState('')
  useEffect(()=> {
    if(cardType==='mastercard'){setCardRegex(/^(?:5[1-5][0-9]{14})$/)}else if(cardType==='visa'){setCardRegex(/^4[0-9]{12}(?:[0-9]{3})?$/)};
  },[cardType])
  const [cardExp, setCardExp] = useState('none');
  const [cardcvc, setCardcvc] = useState(1);
  const [custEmail, setCustEmail] = useState('none');
  
  const [inputError, setInputError ] = useState('');

  let thisYY = ((new Date()).getYear()) - 100;
  let thisMM = (new Date()).getMonth();

  const placeOrder = () => {
    if(pickupDeliver==='Deliver'){
      if(!(deliverAdd.toLowerCase()).includes('m5h')){
        setInputError('Please enter a M5H area address');
        document.getElementById('deliveraddress').focus();
        setTimeout(() => {setInputError('');}, 7000);
        return false;
      };
      if(!receiverName || receiverName.length < 2) {
        setInputError('Please enter a valid receiver name');
        document.getElementById('receivername').focus();
        setTimeout(() => {setInputError('');}, 7000);
        return false;
      };
      if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(receiverPhone)) {
        setInputError('Please enter a valid receiver phone number');
        document.getElementById('receiverphone').focus();
        setTimeout(() => {setInputError('');}, 7000);
        return false;
      };
    }
    if(!cardType){
      setInputError('Please select Mastercard or Visa Card');
      setTimeout(() => {setInputError('');}, 7000);
      return false;
    }
    
    if(!cardHolderName || cardHolderName.length < 2){
      setInputError('Please enter the valid name on credit card');
      document.getElementById('cardname').focus();
      setTimeout(() => {setInputError('');}, 7000);
      return false;
    }    
    if(!(cardRegex).test(cardNum)) {
      setInputError('Please enter valid credit card number');
      document.getElementById('cardnumber').focus();
      setTimeout(() => {setInputError('');}, 7000);
      return false;
    }
    if(cardExp==='none' || cardExp.length!==5 || !cardExp.includes('/') || cardExp.split('/')[1]<thisYY || cardExp.split('/')[0]>12
       || isNaN(cardExp.split('/')[0]) || isNaN(cardExp.split('/')[1]) || (cardExp.split('/')[1]===thisYY ? cardExp.split('/')[0]<(thisMM+1) : '')){
      setInputError('Please enter valid credit card expiry mm/yy');
      document.getElementById('cardexpiry').focus();
      setTimeout(() => {setInputError('');}, 7000);
      return false;
    }
    if(cardcvc===1 || cardcvc.length<3 || cardcvc.length>4){
      setInputError('Please enter credit card CVC code');
      document.getElementById('cardcvccode').focus();
      setTimeout(() => {setInputError('');}, 7000);
      return false;
    }
    if(!(/^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+([a-z0-9]+)*(\.[a-z0-9]+([a-z0-9]+)*)*\.[a-z]{2,4}$/).test(custEmail)){
      setInputError('Please enter your valid email');
      document.getElementById('custemailadd').focus();
      setTimeout(() => {setInputError('');}, 7000);
      return false;
    }
    axios.post('https://api.cbdhotelrestaurant.com:443/mealOrder', {orderDate: orderDate, timeSlot: st, stShow: stShow, dishCart: dishCart, serviceType: pickupDeliver,
              address: deliverAdd, receiver: receiverName, phone: receiverPhone, totalPayment: totalPayment, cardType: cardType, 
              cardName: cardHolderName, cardNumber: cardNum, cardExpiry: cardExp, cardCVC: cardcvc, yourEmail: custEmail})
            .then(res=>{
                if (res.data==='Success') {
                    setInputError('Order confirmed! Check your email.');
                    setTimeout(()=>{document.getElementById('orderfb').style.color='green';}, 100)
                    setTimeout(() => {setInputError('');}, 6000);
                    setTimeout(() => {setDishCart([]);}, 7000);                 
                } else {
                  setInputError(res.data);
                  setTimeout(() => {setInputError('');}, 8000);
                }
            })
  }
  

  return (
    <div>
        <div className='resturantheader'>
          <div id='resturant' >
              <div id='cbdletters' style={{marginRight:'0.6rem'}} >CBD</div>
              <Link to='/Restaurant' style={{textDecoration: 'none'}} ><div id='ckresturantletters'>Restaurant</div></Link>
          </div>
          <div id='ckcheckoutletters'>
              <span style={{color:'blue'}}>C</span><span style={{color:'red'}}>h</span><span style={{color:'orange'}} >e</span><span style={{color:'blue'}}>c</span><span style={{color:'green'}}>k</span><span style={{color:'fuchsia'}}>o</span><span style={{color:'navy'}}>u</span><span style={{color:'red'}}>t</span>                    
              <img src={shoppingcart} width='15' alt='shopping-cart'/><span id='itemsnum' style={{fontSize:'0.8rem', color:'red'}}>{cartItemQty}</span>
          </div>          
          <Link to='/CancelOrder' style={{textDecoration: 'none'}} ><div id='ckcancelorderletters'>Cancel Order</div></Link>
          <Link to='/' style={{textDecoration: 'none'}} ><div id='ckrest-moscodeletters'>Moscode</div></Link>
        </div>

        {cartItemQty===0 && 
          <div id='emptydishcart'style={{color:'rgb(40, 40, 178)'}} >
            <h1>Your Dish Cart is Empty</h1><br/>
            <Link to='/Restaurant' style={{textDecoration:'none', cursor:'pointer'}}><h2>Go to Restaurant</h2></Link>
          </div>
        }
        {stShow==='' &&
          <div id='nost' style={{color:'darkgreen'}} >
            <h1>You Need A Service Time Slot</h1><br/>
            <Link to='/Restaurant' style={{textDecoration:'none', cursor:'pointer'}}><h2>Go to Restaurant </h2></Link>
          </div>
        }
        {(cartItemQty===0 || stShow==='') && <div id='restimg' ><img src={restaurant} width='100%' alt='resturant'/></div>}
        {(cartItemQty>0 && stShow!=='') && 
          <div>
            <br/><br/><br/><br/><br/>
            <div id='acknowledge' >Upon you place the order, you acknowledge that you have read and accepted CBD's <Link to='/About' style={{textDecoration: 'none'}} ><span style={{color:'blue',cursor:'pointer'}}>Privacy Policy</span></Link> and <Link to='/About' style={{textDecoration: 'none'}} ><span style={{color:'blue',cursor:'pointer'}}>Restaurant Sales Agreement.</span></Link> </div>
            <br/>
            <div id='mealdateslot'>
              <div id='dmealdate' ><span id='mealdate' >Your Meal Date: </span><span> {orderDate}</span></div>
              <div id='dmealslot' ><span id='mealslot' >Your Meal Time Slot: </span><span> {stShow}</span></div>
            </div>
            <div id='dishcartdiv' >
              {dishCart.map((item)=>(
                <div className='dishgrid' key={item[0]} item={item}>
                  <img src={process.env.PUBLIC_URL + item[3]} style={{width:'100%'}} alt=''/><br/>
                  <div><b>&nbsp; &nbsp;{item[1]}</b></div>
                  <div>&nbsp; &nbsp;$<span>{item[2]}</span> &nbsp;x&nbsp; {item[4]} = $<span>{((parseFloat(item[2]))*(parseFloat(item[4]))).toFixed(2)}</span></div><br/>
                  <div style={{fontSize:'0.7rem',color:'red',cursor:'pointer'}} onClick={()=>{removeFromCart(item)}} >&nbsp; &nbsp;Remove from Cart</div><br/>
                </div>                
              ))}
            </div>
            <div style={{width:'80%',margin:'0 0 1% 9%'}}><hr/></div>
            <div id='pickupdeliver' >
              <input className="delivery" type="radio" name="delivery" value="Pickup" style={{marginBottom:'2%'}} onChange={e=>setPickupDeliver(e.target.value)} />&nbsp;Pick up at E, 5 King St. West, Toronto M5H 1A1 &nbsp;&nbsp;<br/>
              <input className="delivery" type="radio" name="delivery" value="Deliver" style={{marginBottom:'2%'}} onChange={e=>setPickupDeliver(e.target.value)} />&nbsp;Pay $15 to deliver to an address within M5H area<br/>
            </div>
            {pickupDeliver==='Deliver' && 
              <div>
                <div id='deliverdetail' >
                  <div style={{textAlign:'center'}} >You are responsible to provide complete, accurate delivery information:</div>
                  <div style={{marginTop:'0.4rem'}} >Deliver Meal to: &nbsp;&nbsp; <input id='deliveraddress' type='text' onChange={(e)=>setDeliverAdd(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Address must be in M5H area ...' /></div>
                  <div style={{marginTop:'0.8rem'}}>Receiver Name: &nbsp;&nbsp;<input id='receivername' type='text' onChange={(e)=>setReceiverName(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder="Enter receiver's name ..." /></div>
                  <div style={{marginTop:'0.8rem'}}>Receiver Phone:&nbsp; <input id='receiverphone' type='number' onChange={(e)=>setReceiverPhone(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder="Enter receiver's phone # ..." /></div>
                </div><br/>
              </div>
            }
            <div style={{width:'80%',margin:'0 0 1% 9%'}}><hr/></div>
            <div id='paysummary' >
              <div>&nbsp; &nbsp; Total Dishes:<span style={{float:'right'}}>{cartItemQty} &nbsp; &nbsp; &nbsp; </span></div>
              <div>&nbsp; &nbsp; Subtotal: <span style={{float:'right'}}>${subtotal} &nbsp; &nbsp; &nbsp; </span></div>
              <div>&nbsp; &nbsp; {pickupDeliver}&nbsp;Fee: <span style={{float:'right'}}>${shoppingFee} &nbsp; &nbsp; &nbsp; </span></div>
              <div>&nbsp; &nbsp; Tax 13%: <span style={{float:'right'}}>${tax} &nbsp; &nbsp; &nbsp; </span></div>
              <hr/>
              <div>&nbsp; &nbsp; Total Pamyent: <span style={{float:'right'}}>${totalPayment} &nbsp; &nbsp; &nbsp; </span></div>
            </div>
            <br/>
            <div style={{width:'80%',margin:'0 0 1% 9%'}}><hr/></div>
            <div id='creditcardnote' >Credit card charge is through a professional service provider. For demo purpose please use following dummy credit card or your own dummy credit card.<br/>
              <div style={{textAlign:'center'}} > Master Card: 5434 4075 8163 5956; CVC: 123 </div><div style={{textAlign:'center'}}> Visa Card: 4505 5002 3548 6183; CVC: 456 </div>
            </div>
            <div id='clmealcredittype' style={{width:'60%',margin:'0 0 1% 20%',textAlign:'center'}} ><input onChange={e=>setCardType(e.target.value)} type='radio' name='cardtype' value='mastercard' /> MasterCard &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <input onChange={e=>setCardType(e.target.value)} type='radio' name='cardtype' value='visa' /> Visa</div>
            <div id='cardnamenumber' >
              <div>Card Holder Name: &nbsp;<input id='cardname' type='text' onChange={(e)=>setCardHolderName(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Enter the name on credit card' /> </div>
              <div>Card Number: &nbsp;<input id='cardnumber' type='number' onChange={(e)=>setCardNum(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Enter 16 digit card number ...' /> </div>
            </div>
            <div id='cardexpirycvc' >
              <div>Card Expiry Date: &nbsp; &nbsp; <input id='cardexpiry' type='text' onChange={(e)=>setCardExp(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Enter the expiry mm/yy' /> </div>
              <div>Card CVC/CVV: <input id='cardcvccode' type='number' onChange={(e)=>setCardcvc(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Enter CVC or CVV code' /> </div>
            </div>
            <div id='youremail' >
              <div style={{marginTop:'0.3rem'}} >Your Email: &nbsp;</div>
              <div><input id='custemailadd' type='text' onChange={(e)=>setCustEmail(e.target.value)} style={{width:'18rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Your email to receive order details ...' /></div>
            </div>
            
            {inputError!=='' && <div id='orderfb' >{inputError}</div>}
            <br/><div id='orderbtndiv' ><button id='orderbtn' onClick={()=>placeOrder()}>Place Meal Order</button></div><br/><br/><br/>
            <div style={{width:'80%',margin:'0 0 1% 9%'}}><hr/></div>
          </div>
        }       

    </div>
  )
}

export default Checkout

