import React from 'react';
import axios from 'axios';
import menu from '../images/menu.png';
import facebook from '../images/facebook.png';
import './Moscode.css';
import { useState } from 'react';
import {Link} from 'react-router-dom';


function Moscode() {
    const [feedback, setFeedback] = useState('');
    const [msgdata, setMsgdata] = useState({webMsgSenderName:'', webMsgSenderEmail:'', webMsgContent:'' });
    function handle(e) {
        const newmsgdata = {...msgdata}
        newmsgdata[e.target.id] = e.target.value
        setMsgdata(newmsgdata)
    }
    function submit(e) {
        e.preventDefault();
        let regName = /^[a-zA-Z ]+$/
        let emailPattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+([a-z0-9]+)*(\.[a-z0-9]+([a-z0-9]+)*)*\.[a-z]{2,4}$/;
        if (msgdata.webMsgSenderName.length <= 1 || !regName.test(msgdata.webMsgSenderName)) {
            setFeedback('Please enter valid name');
            document.getElementById('msgfeedback').style.color='red';
            document.getElementById('webMsgSenderName').focus()
            return false;
        }
        if (!emailPattern.test(msgdata.webMsgSenderEmail)) {
            setFeedback('Please enter valid email');
            document.getElementById('msgfeedback').style.color='red';
            document.getElementById('webMsgSenderEmail').focus()
            return false;
        }
        if (msgdata.webMsgContent.length <= 3 || msgdata.webMsgContent.length >= 1500) {
            setFeedback('Please enter valid message');
            document.getElementById('msgfeedback').style.color='red';
            document.getElementById('webMsgContent').focus()
            return false;
        }
        axios.post('https://api.cbdhotelrestaurant.com:443/webMsg', {webMsgSenderName: msgdata.webMsgSenderName, webMsgSenderEmail: msgdata.webMsgSenderEmail, webMsgContent: msgdata.webMsgContent})
            .then(res=>{
                if (res.data==='Success') {
                    setFeedback('Message sent successfully!');
                    document.getElementById('msgfeedback').style.color='green';
                    msgdata.webMsgSenderName='';
                    document.getElementById('webMsgSenderName').innerHTML='';
                    msgdata.webMsgSenderEmail='';
                    document.getElementById('webMsgSenderEmail').innerHTML='';
                    msgdata.webMsgContent='';
                    document.getElementById('webMsgContent').innerHTML='';
                    setTimeout(()=> {
                        document.getElementById('msgfeedback').innerHTML='';
                    }, 5000)
                }
            })
    }

    function showmpmenu() {
        const mpmenumoscode = document.getElementById('mpmenumoscode');
        mpmenumoscode.style.display = 'initial';
        setTimeout(() => {
            mpmenumoscode.style.display = 'none';
        }, 5000);
    }

  return (
    <div>
        <div className='moscodeheader'>
            <div id='menuicon' onClick={showmpmenu} ><img src={menu} alt='menu' width='20'/></div>
            <div id='moscodecanada' >
                <div id='home-moscodeletters'>
                    <span style={{color:'blue'}}>M</span><span style={{color:'red'}}>o</span><span style={{color:'orange'}} >s</span>
                    <span style={{color:'blue'}}>C</span><span style={{color:'green'}}>o</span><span style={{color:'fuchsia'}}>d</span><span style={{color:'navy'}}>e</span>
                </div>
                <div id='canadaletters'>Canada</div>
            </div>
            <Link to='/Hotel' style={{textDecoration: 'none'}} ><div id='cbdhotelletters'>CBD Hotel</div></Link>            
            <Link to='/Restaurant' style={{textDecoration: 'none'}} ><div id='cbdresturantletters'>CBD Restaurant</div></Link>
        </div>

        <div id='moscodecontent'>
            <h3>Move Your Business Online</h3>
            <p style={{textAlign:'left'}}>Every business and service should go online so that the system can work for you 24/7. Once set up the system, you seem to have reliable sales team, accounting team and expert staff that would work all around the year and make no mistake. Your many business procedures are done automatically by the system.</p>
            <p style={{textAlign:'left'}}>Your market reaches everywhere there is access to internet and you have potentials all over the world. You have your own advertisement platform that can attract more customers and promote your business and service. You can retain customers by advertising, promoting through customer email system.</p>
            <p style={{textAlign:'left'}}>As many routine daily works done by the system, your staff can focus on customer services, on product improvement, etc. Your business and services will become more competitive, more efficient and more successful. If your business or services still have not an online system yet, just send me a message below, I will give you a proper solution.</p>
            <h3>eCommerce Demo</h3>
            <p style={{textAlign:'left'}}>I take the most common business, restaurant and hotel for instance, to demonstrate eCommerce & eService. Skipping decorative, advertising, customer rating parts, I show the backbone of business – realization of transactions on an eCommerce website. All sales order, cancel order are safely recorded in database and notified through email.</p>
            <p style={{textAlign:'left'}}>Sales or service should be linked with available inventory or capacity to avoid order cancellation by your business. Each sales order or cancel order will be applied with your accounting rules and business rules and be notified through email. Inventory or capacity should be updated simultaneously with each sales order or cancel order. Payment and refund should be clearly explained in website and showed in notification email.</p>
            <p style={{textAlign:'left'}}>More technically eCommerce must solve some problems, such as customer privacy protection, many customer purchase same item at same time, make more user friendly pages, etc. Now just try this demo eCommerce and send your message to me below. </p>
            <h3>Contact Us</h3>
        </div>

        <div id='webmsg'>
            <form onSubmit={(e)=>submit(e)}>
                <div id='sendernameemail'>
                    <div id='sendername' style={{margin:'0 4rem 0 4rem', fontWeight:'bold'}}>
                        <label>Name: </label>
                        <input onChange={(e)=>handle(e)} id='webMsgSenderName' value={msgdata.webMsgSenderName} type='text' placeholder='Your Name ...' style={{width:'15rem', height:'1.5rem'}} />
                    </div>
                    <div id='senderemail' style={{fontWeight:'bold'}}>
                        <label>Email: </label>
                        <input onChange={(e)=>handle(e)} id='webMsgSenderEmail' value={msgdata.webMsgSenderEmail} type='email' placeholder='Your Email ...' style={{width:'15rem', height:'1.5rem'}} />
                    </div>
                </div>
                
                <div id='msgtextarea'>
                    <label style={{padding:'1rem 0 0.3rem 0.3rem', fontWeight:'bold'}} >Send us message: </label>
                    <textarea onChange={(e)=>handle(e)} id='webMsgContent' value={msgdata.webMsgContent} maxLength={1500} cols={100} rows={15} placeholder='Send us message within 1500 characters ...' />
                </div>
                <div id='feedbacksendbtn' >
                    <div id='msgfeedback'>{feedback}</div><div><button id='msgsendbtn' >Send</button></div>
                </div>
            </form>
        </div>
        <div id='moscodefooter' >Copyright &copy; 2022 ~ 2026 MosCode Canada  All Rights Reserved &nbsp; <a href='https://www.facebook.com/mo.wu.948/' > <img src={facebook} alt='facebook' width='20' /></a></div>
        
        <div id='mpmenumoscode'>
            <Link to='/Hotel' style={{textDecoration: 'none', color:'Black'}} ><div className='mpmenudivs'>CBD Hotel</div></Link>
            <Link to='/Restaurant' style={{textDecoration: 'none', color:'Black'}} ><div className='mpmenudivs'>CBD Resturant</div></Link>
            <Link to='/About' style={{textDecoration: 'none', color:'Black'}} ><div className='mpmenudivs'>About</div></Link>
        </div>
    </div>
  )
}

export default Moscode