import React, {useContext, useRef} from 'react';
import DishContext from '../DishContext';
import './DishCard.css';

function DishCard({dish}) {  
  const qtyRef = useRef();  
  const {dishqty, dishCart, setDishCart, } = useContext(DishContext);
  
  const addToDishCart = (dish) => { 
      if(dishqty.length === 0) {
        alert('Please select date first !');
        return;
      }
      qtyRef.current.value = parseInt(qtyRef.current.value);
      dish.remainqty = parseInt(dish.remainqty);
      if(dish.remainqty === 0) {
        qtyRef.current.value = '';
        alert("This dish has been sold out!");
        return;
      }
      if(qtyRef.current.value === '' ||  qtyRef.current.value === 0 || qtyRef.current.value < 1) {
        qtyRef.current.value = '';
        qtyRef.current.focus();
        alert("Please enter a valid quantity!");
        return;
      }
      if(qtyRef.current.value > dish.remainqty) {
        qtyRef.current.value = '';
        qtyRef.current.focus();
        alert("Quantity should be no more than " + dish.remainqty);
        return;
      } 
      if(qtyRef.current.value >= 1 && qtyRef.current.value <= dish.remainqty) {
        dish.yourqty = qtyRef.current.value;
        setDishCart(dishCart => [...dishCart, [dish.id, dish.name, dish.price, dish.image, dish.yourqty]]);
        return;
      }
  };
  const removeFromDishCart = (dish) => {
    setDishCart(dishCart.filter((item)=>item[0]!== dish.id));
  }

  return (
    <div className='dishmenu-container'>
      <div><img src={dish.image} width='200' alt={dish.name}/>&nbsp; &nbsp; &nbsp;</div>
      <div>
          <br/>
          <b>{dish.name}</b><br/><br/>
          <span>Price: </span>$<span>{dish.price}</span><br/>
          <span>Remaining quantity: </span>{dish.remainqty==='undefined' ? '' : <span>{dish.remainqty}</span>}<br/>
          <span>Your quantity: </span> {dishCart.some(d=>d[0]===dish.id) ? <span>&nbsp; {dishCart[dishCart.findIndex(d=>d[0]===dish.id)][4]}</span>: 
              <input type='number' min='1' max={dish.remainqty} ref={qtyRef} style={{width:'2.3rem'}} />} <br/><br/>
          <div>
            {dishCart.some(d=>d[0]===dish.id) ? 
              <button className='removefromcart' onClick={()=>removeFromDishCart(dish)} >Remove from Cart</button> :
              <button className='addtocart' style={{width:'10rem'}} onClick={()=>addToDishCart(dish)} >Add to Cart</button>}
          </div>          
      </div> 
    </div>
  )  
};

export default DishCard
