import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Moscode from './components/Moscode'
import Restaurant from './components/Restaurant'
import Checkout from './components/Checkout'
import CancelOrder from './components/CancelOrder'
import CancelRoom from './components/CancelRoom'
import Hotel from './components/Hotel'
import About from './components/About'
import { DishProvider } from './DishContext'


function App() {
 
  return (
    <div>
      <DishProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Moscode />} />            
            <Route path='/Restaurant' element={<Restaurant />} />
            <Route path='/Checkout' element={<Checkout />} />
            <Route path='/CancelOrder' element={<CancelOrder />} />
            <Route path='/CancelRoom' element={<CancelRoom />} />           
            <Route path='/Hotel' element={<Hotel />} />
            <Route path='/About' element={<About />} />
          </Routes>
        </BrowserRouter>
      </DishProvider>
    </div>
  )
}

export default App

