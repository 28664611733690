import React, {useContext, useState}  from 'react';
import './Restaurant.css';
import {Link} from 'react-router-dom'
import shoppingcart from '../images/shoppingcart.png';
import DishContext from '../DishContext';
import DishCard from './DishCard'
import axios from 'axios';

function Restaurant() {
    const formatDate = (date) => {
      var d = new Date(date);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      return [year, month, day].join('-');
    };
    let thisday = new Date();
    let maxdate =formatDate(thisday.setDate(thisday.getDate()+10));
    let today = formatDate(new Date());
    let ct = thisday.getHours() + Math.round((thisday.getMinutes()/60)*10)/10;
    const {dishqty, handleDateChange, dishes, serviceTime, setServiceTime, cartItemQty, setSt, stShow } = useContext(DishContext);
    const [showTimeslot, setshowTimeslot] = useState(false);
    
    const openServiceTime = () => {
      if(!dishqty.dishdate){
        alert("Please select date first !");
        return;
      } else {
        let servicedate = dishqty.dishdate;
        axios.get('https://api.cbdhotelrestaurant.com:443/serviceTime/' + servicedate).then(res=>{
            setServiceTime(res.data);            
        })
        setshowTimeslot(true);
      }
    }
    if(showTimeslot){document.body.classList.add('active-modal')}else{document.body.classList.remove('active-modal')}
    const getSt = (btnSt) => {setSt(btnSt)}


  return (
    <div>
        <div className='resturantheader'>
            <div id='resturant' >
                <div id='cbdletters' style={{marginRight:'0.6rem'}} >CBD</div>
                <div id='resturantletters'>
                    <span style={{color:'blue'}}>R</span><span style={{color:'red'}}>e</span><span style={{color:'orange'}} >s</span><span style={{color:'blue'}}>t</span><span style={{color:'pink'}}>a</span><span style={{color:'green'}}>u</span><span style={{color:'fuchsia'}}>r</span><span style={{color:'navy'}}>a</span><span style={{color:'blue'}}>n</span><span style={{color:'red'}}>t</span>
                </div>
            </div>
            <Link to='/Checkout' style={{textDecoration: 'none'}} ><div id='checkoutletters'>Checkout <img src={shoppingcart} width='15' alt='shopping-cart'/><span id='itemsnum' style={{fontSize:'0.8rem', color:'red'}}>{cartItemQty}</span></div></Link>            
            <Link to='/CancelOrder' style={{textDecoration: 'none'}} ><div id='cancelorderletters'>Cancel Order</div></Link>
            <Link to='/' style={{textDecoration: 'none'}} ><div id='rest-moscodeletters'>Moscode</div></Link>
        </div>
        <div id='resturantintro' >
          Besides service in restaurant site, CBD Restaurant also provides online purchase to better serve community in downtown Toronto. Customer can place an order in upto 10 days and pick up the order or ask us to deliver. Delivery orders are limited to the area within post code M5H. $15 delivery fee applies. Daily supply is limited, first order first serve.<br/>
          <b>CBD Restaurant Address</b>: E, 5 King St. West, Toronto M5H 1A1  
        </div>
        <div id='date-servicetime'>
            <div id='date'style={{textAlign:'center'}} >
              <label style={{fontWeight:'bold'}}>Select date to see available quantity:</label><br/>
              <input id='dateinput' type='date' onChange={handleDateChange} min={today} max={maxdate} /><span>{dishqty.dishdate}</span>
            </div>
            <div id='servicetime'style={{marginTop:'1rem',textAlign:'center'}} >
              <span id='timeslotbtn' onClick={openServiceTime}>Select service time</span><span id='selectedtimeslot'>{stShow}</span>
            </div>
        </div>
        
        <div className='menu-row'>
          {dishes.map((dish)=> (
              <DishCard key={dish.id} dish={dish} />
            ))} 
        </div>
        
        <br/><br/><br/><br/><br/><br/>

        {showTimeslot && 
          <div id='servicetimeoverlay' onClick={()=>setshowTimeslot(false)}>
            <div id='mp4servicetime' onClick={(e)=>{e.stopPropagation()}}>                          
                <div id='insidemp' >
                  <div style={{color:'rgb(170, 28, 28)',fontSize:'xx-large',fontWeight:'bold',marginLeft:'92%',cursor:'pointer'}}><span onClick={()=>setshowTimeslot(false)}>&times;</span></div>
                  <div style={{color:'white',textAlign:'center'}} ><b>Service Date:&nbsp; {dishqty.dishdate}</b></div>
                  <div style={{color:'white',textAlign:'center', marginTop:'0.5rem'}} >Select Your Time Slot: {stShow}</div>
                  {dishqty.dishdate===today && 
                    <div style={{color:'white',textAlign:'center', marginTop:'0.5rem'}} >
                      {(serviceTime.st1>0 && ct<8.4) && <div className='timeslotrow'><button onClick={()=>getSt('st1')}>10:30 -- 11:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st1} Slot Remain</span></div>}
                      {(serviceTime.st2>0 && ct<9) && <div className='timeslotrow'><button onClick={()=>getSt('st2')}>11:00 -- 11:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st2} Slot Remain</span></div>}
                      {(serviceTime.st3>0 && ct<9.4) && <div className='timeslotrow'><button onClick={()=>getSt('st3')}>11:30 -- 12:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st3} Slot Remain</span></div>}
                      {(serviceTime.st4>0 && ct<10) && <div className='timeslotrow'><button onClick={()=>getSt('st4')}>12:00 -- 12:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st4} Slot Remain</span></div>}
                      {(serviceTime.st5>0 && ct<10.4) && <div className='timeslotrow'><button onClick={()=>getSt('st5')}>12:30 -- 13:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st5} Slot Remain</span></div>}
                      {(serviceTime.st6>0 && ct<11) && <div className='timeslotrow'><button onClick={()=>getSt('st6')}>13:00 -- 13:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st6} Slot Remain</span></div>}
                      {(serviceTime.st7>0 && ct<14.4) && <div className='timeslotrow'><button onClick={()=>getSt('st7')}>16:30 -- 17:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st7} Slot Remain</span></div>}
                      {(serviceTime.st8>0 && ct<15) && <div className='timeslotrow'><button onClick={()=>getSt('st8')}>17:00 -- 17:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st8} Slot Remain</span></div>}
                      {(serviceTime.st9>0 && ct<15.4) && <div className='timeslotrow'><button onClick={()=>getSt('st9')}>17:30 -- 18:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st9} Slot Remain</span></div>}
                      {(serviceTime.st10>0 && ct<16) && <div className='timeslotrow'><button onClick={()=>getSt('st10')}>18:00 -- 18:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st10} Slot Remain</span></div>}
                      {(serviceTime.st11>0 && ct<16.4) && <div className='timeslotrow'><button onClick={()=>getSt('st11')}>18:30 -- 19:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st11} Slot Remain</span></div>}
                      {(serviceTime.st12>0 && ct<17) && <div className='timeslotrow'><button onClick={()=>getSt('st12')}>19:00 -- 19:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st12} Slot Remain</span></div>}
                    </div>
                  }
                  {dishqty.dishdate!==today && 
                    <div style={{color:'white',textAlign:'center', marginTop:'0.5rem'}} >
                      {serviceTime.st1>0 && <div className='timeslotrow'><button onClick={()=>getSt('st1')}>10:30 -- 11:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st1} Slot Remain</span></div>}
                      {serviceTime.st2>0 && <div className='timeslotrow'><button onClick={()=>getSt('st2')}>11:00 -- 11:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st2} Slot Remain</span></div>}
                      {serviceTime.st3>0 && <div className='timeslotrow'><button onClick={()=>getSt('st3')}>11:30 -- 12:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st3} Slot Remain</span></div>}
                      {serviceTime.st4>0 && <div className='timeslotrow'><button onClick={()=>getSt('st4')}>12:00 -- 12:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st4} Slot Remain</span></div>}
                      {serviceTime.st5>0 && <div className='timeslotrow'><button onClick={()=>getSt('st5')}>12:30 -- 13:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st5} Slot Remain</span></div>}
                      {serviceTime.st6>0 && <div className='timeslotrow'><button onClick={()=>getSt('st6')}>13:00 -- 13:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st6} Slot Remain</span></div>}
                      {serviceTime.st7>0 && <div className='timeslotrow'><button onClick={()=>getSt('st7')}>16:30 -- 17:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st7} Slot Remain</span></div>}
                      {serviceTime.st8>0 && <div className='timeslotrow'><button onClick={()=>getSt('st8')}>17:00 -- 17:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st8} Slot Remain</span></div>}
                      {serviceTime.st9>0 && <div className='timeslotrow'><button onClick={()=>getSt('st9')}>17:30 -- 18:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st9} Slot Remain</span></div>}
                      {serviceTime.st10>0 && <div className='timeslotrow'><button onClick={()=>getSt('st10')}>18:00 -- 18:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st10} Slot Remain</span></div>}
                      {serviceTime.st11>0 && <div className='timeslotrow'><button onClick={()=>getSt('st11')}>18:30 -- 19:00</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st11} Slot Remain</span></div>}
                      {serviceTime.st12>0 && <div className='timeslotrow'><button onClick={()=>getSt('st12')}>19:00 -- 19:30</button><span style={{fontSize:'0.9rem'}}>&nbsp; {serviceTime.st12} Slot Remain</span></div>}
                    </div>
                  }
                  <br/><br/>
                  <div style={{color:'white',width:'76%',marginLeft:'12%'}}>Other customer's order may affect the availability of dishes and service time slot, so the quicker you place an order, the better.</div>
                  <br/>
                </div>            
            </div>
          </div>
        }
        
    </div>
  )
}

export default Restaurant
