import React, {useEffect, useState} from 'react';
import './Hotel.css';
import {Link} from 'react-router-dom'
import {MdArrowBackIos, MdArrowForwardIos} from 'react-icons/md';
import axios from 'axios';

function Hotel() {
  const stdroom = [{image:`${process.env.PUBLIC_URL}/pics/std1.jpg`},{image:`${process.env.PUBLIC_URL}/pics/std2.jpg`}]
  const [currentStdroom, setCurrentStdroom] = useState(0);
  const nextStdroom = () => {setCurrentStdroom(currentStdroom === 2 - 1 ? 0 : currentStdroom + 1)}
  const prevStdroom = () => {setCurrentStdroom(currentStdroom === 0 ? 2 - 1 : currentStdroom - 1)}
  useEffect(() => {setCurrentStdroom(0)}, [])
  const fmyroom = [{image:`${process.env.PUBLIC_URL}/pics/family1.jpg`},{image:`${process.env.PUBLIC_URL}/pics/family2.jpg`},{ image:`${process.env.PUBLIC_URL}/pics/family3.jpg`},{image:`${process.env.PUBLIC_URL}/pics/family4.jpg`}, {image:`${process.env.PUBLIC_URL}/pics/family5.jpg`}]
  const [currentFmyroom, setCurrentFmyroom] = useState(0);
  const nextFmyroom = () => {setCurrentFmyroom(currentFmyroom === 5 - 1 ? 0 : currentFmyroom + 1)}
  const prevFmyroom = () => {setCurrentFmyroom(currentFmyroom === 0 ? 5 - 1 : currentFmyroom - 1)}
  useEffect(() => {setCurrentFmyroom(0)}, [])
  const viproom = [{image:`${process.env.PUBLIC_URL}/pics/vip1.jpg`},{image:`${process.env.PUBLIC_URL}/pics/vip2.jpg`},{ image:`${process.env.PUBLIC_URL}/pics/vip3.jpg`},{image:`${process.env.PUBLIC_URL}/pics/vip4.jpg`}, {image:`${process.env.PUBLIC_URL}/pics/vip5.jpg`}]
  const [currentViproom, setCurrentViproom] = useState(0);
  const nextViproom = () => {setCurrentViproom(currentViproom === 5 - 1 ? 0 : currentViproom + 1)}
  const prevViproom = () => {setCurrentViproom(currentViproom === 0 ? 5 - 1 : currentViproom - 1)}
  useEffect(() => {setCurrentViproom(0)}, [])
  const mtgroom = [{image:`${process.env.PUBLIC_URL}/pics/meeting1.jpg`},{image:`${process.env.PUBLIC_URL}/pics/meeting2.jpg`},{ image:`${process.env.PUBLIC_URL}/pics/meeting3.jpg`},{image:`${process.env.PUBLIC_URL}/pics/meeting4.jpg`}]
  const [currentMtgroom, setCurrentMtgroom] = useState(0);
  const nextMtgroom = () => {setCurrentMtgroom(currentMtgroom === 4 - 1 ? 0 : currentMtgroom + 1)}
  const prevMtgroom = () => {setCurrentMtgroom(currentMtgroom === 0 ? 4 - 1 : currentMtgroom - 1)}
  useEffect(() => {setCurrentMtgroom(0)}, [])
  const formatDate = (date) => {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-');
  };
  let thisday = new Date();
  let maxdatearrival =formatDate(thisday.setDate(thisday.getDate()+100));
  let today = formatDate(new Date());
  let mindeparture = formatDate((new Date()).setDate((new Date()).getDate()+1));
  let maxdeparture = formatDate((new Date()).setDate((new Date()).getDate()+130));
  const [roomType, setRoomType] = useState('');
  const [roomTypeStr, setRoomTypeStr] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [departDate, setDepartDate] = useState('');
  const [min2, setMin2] = useState();
  useEffect(()=>{ setMin2(formatDate((new Date(arrivalDate)).setDate((new Date(arrivalDate)).getDate()+2))) }, [arrivalDate]);
  const handleArrivalDate = (e) => {e.preventDefault(); setArrivalDate(document.getElementById('datearrival').value)};
  const handleDepartDate = (e) => {e.preventDefault(); setDepartDate(document.getElementById('datedeparture').value)};  
  const [lastDay, setLastDay] = useState('');
  useEffect(() => {let [y, m, d] = departDate.split('-'); const lastdate = new Date(y, m-1, d);
    setLastDay(formatDate(lastdate.setDate(lastdate.getDate()-1)))}, [departDate]);
  const [serchRoomFeedBack, setSerchRoomFeedBack] = useState('');  
  const [roomInfoList, setRoomInfoList] = useState('');
  const [availableRooms, setAvailableRooms] = useState('');
  const [pricePerRoom, setPricePerRoom] = useState();  
  useEffect(()=> { let min = 50; let roomPriceSum = 0;
    for(let rmInfo of roomInfoList) {
      if(rmInfo.rooms < min){ min = rmInfo.rooms;};
      roomPriceSum += rmInfo.price;
    }
    setPricePerRoom(roomPriceSum); setAvailableRooms(min);
  },[roomInfoList])
  const [roomQty, setRoomQty] = useState(1);
  const [hst, setHst] = useState(0.00);
  const [ttlPayment, setTtlPayment] = useState(0.00)
  useEffect (()=> {setHst(parseFloat(roomQty*pricePerRoom*0.13).toFixed(2)); setTtlPayment(parseFloat(roomQty*pricePerRoom*1.13).toFixed(2));},[pricePerRoom, roomQty]);
  const [dateList, setDateList] =useState(null);
 
  const serchRoom = () => {    
    if (roomType === '') {
      setSerchRoomFeedBack('Please Select Room Type');
      setRoomTypeStr(''); 
      setTimeout(() => {setSerchRoomFeedBack('');}, 7000); 
      return false;
    };
    if (!arrivalDate || arrivalDate === '') {
      setSerchRoomFeedBack('Please Select Arrival Date'); 
      document.getElementById('datearrival').focus();
      setTimeout(() => {setSerchRoomFeedBack('');}, 7000);
      return false;
    };
    if (!departDate || departDate === '') {
      setSerchRoomFeedBack('Please Select Departure Date'); 
      document.getElementById('datedeparture').focus();
      setTimeout(() => {setSerchRoomFeedBack('');}, 7000);
      return false;
    };
    if (arrivalDate >= departDate ) {
      setSerchRoomFeedBack('Please Select A Later Departure Date'); 
      document.getElementById('datedeparture').focus();
      setTimeout(() => {setSerchRoomFeedBack('');}, 7000);
      return false;
    };
    if (roomType==='standardroom') {setRoomTypeStr('Standard Room')} else if (roomType==='familysuite') {setRoomTypeStr('Family Suite')} else if (roomType==='vipsuite') {setRoomTypeStr('VIP Suite')};
    axios.get('https://api.cbdhotelrestaurant.com:443/roomInfoList', {params: {roomType: roomType, arrivalDate: arrivalDate, lastDay: lastDay}})
            .then(res=>{ setRoomInfoList(res.data); });
    setRoomQty(1); setDateList(null);
  };
  const decrmqty = () => {if (roomQty<=1){setRoomQty(1)} else if (roomQty > 1 && roomQty <= availableRooms){setRoomQty(roomQty - 1)}};
  const incrmqty = () => {if (roomQty>=availableRooms){setRoomQty(availableRooms)} else if (roomQty < availableRooms){setRoomQty(roomQty + 1)}};
  
  const [hCardType, setHCardType] = useState('');
  const [hCardHolderName, setHCardHolderName] = useState('');
  const [hCardNum, setHCardNum] = useState();
  const [cardRegex, setCardRegex] = useState('')
  useEffect(()=> {
    if(hCardType==='mastercard'){setCardRegex(/^(?:5[1-5][0-9]{14})$/)}else if(hCardType==='visa'){setCardRegex(/^4[0-9]{12}(?:[0-9]{3})?$/)};
  },[hCardType])
  const [hCardExpiry, setHCardExpiry] = useState('none');
  const [hcardcvc, setHcardcvc] = useState(1);
  const [hCustEmail, setHCustEmail] = useState('none');
  const [hCustPhone, setHCustPhone] = useState('');

  const proceed = () => { let dl = [];
    for(let i = 0; i < roomInfoList.length; i++){ dl.push(roomInfoList[i].date); };
    setDateList(dl); setHCardType(''); setHCardHolderName(''); setHCardNum(''); setHCardExpiry('none'); setHcardcvc(1); setHCustEmail('none'); setHCustPhone('');
  };
  
  const [hInputError, setHInputError ] = useState('');

  let thisYY = ((new Date()).getYear()) - 100;
  let thisMM = (new Date()).getMonth();

  const placeRoomOrder = () => {
    if(!hCardType){
      setHInputError('Please select Mastercard or Visa Card');
      setTimeout(() => {setHInputError('');}, 7000);
      return false;
    }    
    if(!hCardHolderName || hCardHolderName.length < 2){
      setHInputError('Please enter the valid name on credit card');
      document.getElementById('hcardname').focus();
      setTimeout(() => {setHInputError('');}, 7000);
      return false;
    }    
    if(!(cardRegex).test(hCardNum)) {
      setHInputError('Please enter valid credit card number');
      document.getElementById('hcardnumber').focus();
      setTimeout(() => {setHInputError('');}, 7000);
      return false;
    }
    if(hCardExpiry==='none' || hCardExpiry.length!==5 || !hCardExpiry.includes('/') || hCardExpiry.split('/')[1]<thisYY || hCardExpiry.split('/')[0]>12
       || isNaN(hCardExpiry.split('/')[0]) || isNaN(hCardExpiry.split('/')[1]) || (hCardExpiry.split('/')[1]===thisYY ? hCardExpiry.split('/')[0]<(thisMM+1) : '')){
      setHInputError('Please enter valid credit card expiry mm/yy');
      document.getElementById('hcardexpiry').focus();
      setTimeout(() => {setHInputError('');}, 7000);
      return false;
    }
    if(hcardcvc===1 || hcardcvc.length<3 || hcardcvc.length>4){
      setHInputError('Please enter credit card CVC code');
      document.getElementById('hcardcvccode').focus();
      setTimeout(() => {setHInputError('');}, 7000);
      return false;
    }
    if(!(/^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+([a-z0-9]+)*(\.[a-z0-9]+([a-z0-9]+)*)*\.[a-z]{2,4}$/).test(hCustEmail)){
      setHInputError('Please enter your valid email');
      document.getElementById('hcustemailadd').focus();
      setTimeout(() => {setHInputError('');}, 7000);
      return false;
    }
    if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(hCustPhone)) {
      setHInputError('Please enter a valid phone number');
      document.getElementById('hcustphone').focus();
      setTimeout(() => {setHInputError('');}, 7000);
      return false;
    };

    axios.post('https://api.cbdhotelrestaurant.com:443/roomOrder', { arrivalDate: arrivalDate, departDate: departDate, dateList: dateList, roomType: roomType, roomQty: roomQty, pricePerRoom: pricePerRoom, hst: hst, ttlPayment: ttlPayment,
             hCardType: hCardType, hCardHolderName: hCardHolderName, hCardNum: hCardNum, hCardExpiry: hCardExpiry, hcardcvc: hcardcvc, hCustEmail: hCustEmail, hCustPhone: hCustPhone})
            .then(res=>{
                if (res.data==='Success') {
                    setHInputError('Room Order confirmed! Check your email.');
                    setTimeout(()=>{document.getElementById('horderfb').style.color='green';}, 100)
                    setTimeout(() => {setHInputError('');}, 6000);
                    setTimeout(() => {setDateList(null);}, 7000);
                    setTimeout(() => {setRoomInfoList('');}, 7500);
                    setTimeout(() => {setAvailableRooms(0);}, 7500);                
                } else {
                  setHInputError(res.data);
                  setTimeout(() => {setHInputError('');}, 8000);
                }
            })
    
  }  
  

  return (
    <div>
        <div className='hotelheader'>
            <div id='hotel' >
                <div id='cbdletters' style={{marginRight:'0.6rem'}} >CBD</div>
                <div id='hotelletters'>
                    <span style={{color:'blue'}}>H</span><span style={{color:'red'}}>o</span><span style={{color:'green'}} >t</span><span style={{color:'blue'}}>e</span><span style={{color:'orange'}}>l</span>
                </div>
            </div>                        
            <Link to='/CancelRoom' style={{textDecoration: 'none'}} ><div id='cancelroomletters'>Cancel Room</div></Link>
            <Link to='/' style={{textDecoration: 'none'}} ><div id='hotel-moscodeletters'>Moscode</div></Link>
        </div>
        <div id='hotelintro' >
          CBD Hotel situates in downtown Toronto. Here is the traffic hub to other parts of Canada. Guests have easy access to Pearson International Airport, Railway to USA, Highways to other cities. A walking distance to Lake Ontario and nearby shopping, entertainment and amenities.
          CBD Hotel has 30 standard guest rooms, 7 family suites and 3 VIP suites, as well as various meeting rooms for holding events. The ground floor is CBD Restaurant providing delicious dishes for our guests.<br/>
          <b>CBD Hotel Address</b>: S, 5 King St. West, Toronto M5H 1A1  
        </div>
        <div id='rmordwarn'>Before you place the order, you acknowledge that you have read and accepted CBD's <Link to='/About' style={{textDecoration: 'none'}} ><span style={{color:'blue',cursor:'pointer'}}>Privacy Policy</span></Link> and <Link to='/About' style={{textDecoration: 'none'}} ><span style={{color:'blue',cursor:'pointer'}}>Hotel Room Order Agreement.</span></Link> 
                   &nbsp;Once you placed an room order, you made a purchase contract. If you cancel the order, a 10% to 30% of payment or minimum $50 cancellation fee applies, depending on when you cancel the order.</div>
        <br/>
        <div className='hotelrow'>
          <div id='std'>
            <div id='stdimgs'>
              <MdArrowBackIos className='arrow prev' onClick={prevStdroom} />
              <MdArrowForwardIos  className='arrow next' onClick={nextStdroom} />
              {stdroom.map((slide, index) => {
                return (
                  <div className={index === currentStdroom ? "slide current" : "slide"} key={index}>
                    {index === currentStdroom && (<><img src={slide.image} alt='Standard Room' style={{width:'100%'}}/></>)}
                  </div>
                )
              })}
            </div><br/>
            <div>
              <b> &nbsp; Standard Guest Room</b><br/>              
               &nbsp; &nbsp; Two queen size beds;<br/>
               &nbsp; &nbsp; Full toilet;<br/>
               &nbsp; &nbsp; Chairs, table and coffe bar;<br/>
               &nbsp; &nbsp; Cable TV and wifi internet;<br/>
               &nbsp; &nbsp; Mon - Thu $150.00/day, Fri - Sun $180.00/day;<br/>
               &nbsp; &nbsp; Max. persons allowed: 2 + 1 under 7 years old;<br/><br/>              
            </div>            
          </div>

          <div id='family'>
            <div id='familyimgs'>
              <MdArrowBackIos className='arrow prev' onClick={prevFmyroom} />
              <MdArrowForwardIos  className='arrow next' onClick={nextFmyroom} />
              {fmyroom.map((slide, index) => {
                return (
                  <div className={index === currentFmyroom ? "slide current" : "slide"} key={index}>
                    {index === currentFmyroom && (<><img src={slide.image} alt='Family Room' style={{width:'100%'}}/></>)}
                  </div>
                )
              })}
            </div><br/>
            <div>
              <b> &nbsp; Family Suite</b><br/>              
               &nbsp; &nbsp; Master room - king size bed, full toilet;<br/>
               &nbsp; &nbsp; Children room - two queen size beds, full toilet;<br/>
               &nbsp; &nbsp; Sitting room - sofa bed, chairs, table and TV set;<br/>
               &nbsp; &nbsp; Kitchen - table, microwave oven and refrigerator;<br/>
               &nbsp; &nbsp; Mon - Thu $290.00/day, Fri - Sun $350.00/day;<br/>
               &nbsp; &nbsp; Max. persons allowed: 4 + 1 under 7 years old;<br/><br/>  
            </div>            
          </div>
        </div><br/><br/>

        <div className='hotelrow'>
          <div id='vip'>
            <div id='vipimgs'>
              <MdArrowBackIos className='arrow prev' onClick={prevViproom} />
              <MdArrowForwardIos  className='arrow next' onClick={nextViproom} />
              {viproom.map((slide, index) => {
                return (
                  <div className={index === currentViproom ? "slide current" : "slide"} key={index}>
                    {index === currentViproom && (<><img src={slide.image} alt='VIP Room' style={{width:'100%'}}/></>)}
                  </div>
                )
              })}
            </div><br/>
            <div>
              <b> &nbsp; VIP Suite</b><br/>              
               &nbsp; &nbsp; Spacious, luxury master room and toilet;<br/>
               &nbsp; &nbsp; Clerk room - two queen size beds, full toilet;<br/>
               &nbsp; &nbsp; Spacious, luxury sitting room;<br/>
               &nbsp; &nbsp; Kitchen - table, microwave oven and refrigerator;<br/>
               &nbsp; &nbsp; Mon - Thu $560.00/day, Fri - Sun $620.00/day;<br/>
               &nbsp; &nbsp; Max. persons allowed: 4 + 1 under 7 years old;<br/><br/>               
            </div>            
          </div>

          <div id='meeting'>
            <div id='meetingimgs'>
              <MdArrowBackIos className='arrow prev' onClick={prevMtgroom} />
              <MdArrowForwardIos  className='arrow next' onClick={nextMtgroom} />
              {mtgroom.map((slide, index) => {
                return (
                  <div className={index === currentMtgroom ? "slide current" : "slide"} key={index}>
                    {index === currentMtgroom && (<><img src={slide.image} alt='Meeting Room' style={{width:'100%'}}/></>)}
                  </div>
                )
              })}
            </div><br/>
            <div>
              <b> &nbsp; Meeting Rooms</b><br/>              
               &nbsp; &nbsp; The Grand Hall for event of 100 persons;<br/>
               &nbsp; &nbsp; One large meeting room for 50 persons;<br/>
               &nbsp; &nbsp; Two board meeting rooms for 20 or 30 persons;<br/>
               &nbsp; &nbsp; All audio video equipments and furnitures;<br/>
               &nbsp; &nbsp; Contact: event@cbdcompany.ca, 416-123-4567;<br/>
               &nbsp; &nbsp; Open to public, but guests have priority;<br/><br/> 
            </div>            
          </div>
        </div><br/><br/>

        <div style={{fontWeight:'bold', textAlign:'center', margin:'0 auto'}}>Select Your Room Type</div><br/>
        <div id='rmtyperow'>
          <div><input onChange={e=>setRoomType(e.target.value)} type='radio' name='rmtype' value='standardroom'/> Standard Room </div>
          <div id='familytype'><input onChange={e=>setRoomType(e.target.value)} type='radio' name='rmtype' value='familysuite'/> Family Suite </div>
          <div><input onChange={e=>setRoomType(e.target.value)} type='radio' name='rmtype' value='vipsuite'/> VIP Suite </div>
        </div><br/><br/>

        <div className='hotelrow'>
          <div id='arrivaldate' style={{textAlign:'center'}}>
            <p style={{fontWeight:'bold'}}>Select Your Arrival Date</p>
            <input id='datearrival' type='date' onChange={handleArrivalDate} min={today} max={maxdatearrival} />
            <p>You will check-in after 12:00 PM</p><br/>
            {arrivalDate !== '' && <p id='parrivaldate' >on {arrivalDate}</p>}
          </div>
          <div id='departuredate' style={{textAlign:'center'}}>
            <p style={{fontWeight:'bold'}}>Select Your Departure Date</p>
            <input id='datedeparture' type='date' onChange={handleDepartDate} min={arrivalDate==='' ? mindeparture : min2} max={maxdeparture} />
            <p>You will check-out before 11:00 AM</p><br/>
            {(departDate !== '' && departDate > arrivalDate) && <p>on {departDate}</p>}
            {(departDate !== '' && departDate <= arrivalDate) && <p style={{color:'red'}}>Departure date must be later than arrival date!</p>}
          </div>          
        </div><br/>
        <div style={{margin:'0 auto', color:'red', textAlign:'center'}}>{serchRoomFeedBack}</div><br/>
        <div id='serchbtndiv' ><button id='serchbtn' onClick={serchRoom}>Search Room Availability</button></div><br/><br/>
        {roomInfoList !== '' && 
          <div id='roominfolistdiv'>
            <table id='roominfotbl'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Day</th>
                  <th>Rooms</th>
                  <th>Unit Price</th>
                </tr>
              </thead>
              <tbody>
                {
                  roomInfoList.map(roomInfo => 
                    <tr key={roomInfo.date}>
                      <td>{roomInfo.date}</td>
                      <td>{roomInfo.day}</td>
                      <td>{roomInfo.rooms}</td>
                      <td>${parseFloat(roomInfo.price).toFixed(2)}</td>
                    </tr>
                  )
                }
              </tbody>
              <tfoot>
                <tr>
                  <td rowSpan='2'>{roomTypeStr}</td>
                  <td>Total Days</td>
                  <td>Available Rooms</td>
                  <td>Unit Price</td>
                </tr>
                <tr>                  
                  <td>{roomInfoList.length}</td>
                  <td style={availableRooms===0 ? {color:'yellow'} : {color : 'white'}}>{availableRooms}</td>
                  <td>${parseFloat(pricePerRoom).toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        }<br/><br/>
        { ( roomInfoList !== '' && availableRooms === 0 ) &&
          <div id='noroomwarning' style={{color:'red',fontWeight:'bold'}}>Change your arrival date, departure date or room type, search again!</div>
        }
        { ( roomInfoList !== '' && availableRooms > 0 ) && 
          <div id='haveroom'>
            <table id='haveroomtble'>
              <thead>
                <tr>
                  <th>Change Rooms</th>
                  <th>Unit Price</th>
                  <th>13% HST</th>
                  <th>Total</th>
                  <th rowSpan='2' style={{padding:'2px 2px'}} ><button id='btnproceed' onClick={proceed}>Proceed</button></th>
                </tr>
                <tr>
                  <th><button id='dec' onClick={decrmqty} > - </button><input type='number' name='rmqtyinput' value={roomQty} min='1' max={availableRooms} step='1' readOnly/><button id='dec' onClick={incrmqty}> + </button></th>
                  <th>${parseFloat(pricePerRoom).toFixed(2)}</th>
                  <th>${hst}</th>
                  <th>${ttlPayment}</th>                  
                </tr>
              </thead>
            </table>
          </div>
        }<br/><br/>
        { dateList !== null && 
          <div>
            <div style={{width:'80%',margin:'0 0 1% 9%'}}><hr/></div>
            <div id='hcreditcardnote' >Credit card charge is through a professional service provider. For demo purpose please use following dummy credit card or your own dummy credit card.<br/>
              <div style={{textAlign:'center'}} > Master Card: 5434 4075 8163 5956; CVC: 123 </div><div style={{textAlign:'center'}}> Visa Card: 4505 5002 3548 6183; CVC: 456 </div>
            </div>
            <div id='hcardtypediv'><input onChange={e=>setHCardType(e.target.value)} type='radio' name='hcardtype' value='mastercard' /> MasterCard &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <input onChange={e=>setHCardType(e.target.value)} type='radio' name='hcardtype' value='visa' /> Visa</div>
            <div id='hcardnamenumber' >
              <div>Card Holder Name: &nbsp;<input id='hcardname' type='text' onChange={(e)=>setHCardHolderName(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Enter the name on credit card' /> </div>
              <div>Card Number: &nbsp;<input id='hcardnumber' type='number' onChange={(e)=>setHCardNum(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Enter 16 digit card number ...' /> </div>
            </div>
            <div id='hcardexpirycvc' >
              <div>Card Expiry Date: &nbsp; &nbsp; <input id='hcardexpiry' type='text' onChange={(e)=>setHCardExpiry(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Enter the expiry mm/yy' /> </div>
              <div>Card CVC/CVV: <input id='hcardcvccode' type='number' onChange={(e)=>setHcardcvc(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Enter CVC or CVV code' /> </div>
            </div>
            <div id='hcustemaiphone' >
              <div>&nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; Your Email: &nbsp; &nbsp; <input id='hcustemailadd' type='text' onChange={(e)=>setHCustEmail(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Your email to receive details ...' /> </div>
              <div>&nbsp; &nbsp;  &nbsp; Your Phone: <input id='hcustphone' type='number' onChange={(e)=>setHCustPhone(e.target.value)} style={{width:'15rem',height:'1.8rem',fontSize:'0.9rem'}} placeholder='Enter your phone # ...' /> </div>
            </div><br/>
            {hInputError!=='' && <div id='horderfb' >{hInputError}</div>}
            <br/><div id='horderbtndiv' ><button id='horderbtn' onClick={()=>placeRoomOrder()}>Place Room Order</button></div><br/><br/><br/>
            <div style={{width:'80%',margin:'0 0 1% 9%'}}><hr/></div>
          </div>
        }        
        <br/><br/><br/><br/>        
    </div>
  )
}

export default Hotel
